<template>
  <tr class="library-item-tr f-thead">
    <td class="draghandle" :class="'-disabled'">
      <span v-if="contentOwnership.hasPriceChanges || hasRecentPriceChange" class="colorindicator">&nbsp;</span>
      <UIIcon class="w2 fill-inherit" name="menu" />
    </td>
    <td class="imagecell dn dtc-m" style="max-width: 150px; min-width: 100px; width: 10%;">
      <RatioContainer class="ratiocontainer" :ratio="46.5863">
        <LazyImg
          class="posterimg absolute w-100 h-100"
          :style="{ opacity: loading ? 0.5 : 1 }"
          :src="contentOwnership.imageUrl"
          :srcset="contentOwnership.imageSrcSet"
          :placeholder="contentOwnership.imageSvgUrl"
          :alt="contentOwnership.title"
        />
      </RatioContainer>
    </td>
    <td class="titlecell">
      <h4
        class="title f-header nowrap dib truncate slate w-100"
        :style="{ opacity: loading ? 0.5 : 1 }"
      >{{ bundle.title }}</h4>
      <h4 class="currentmonthcost db dn-m">
        <span class="f-thead dib v-mid mr1">
          {{ bundle.contentOwnership.currentPrice.displayPriceMonth }} USD
        </span>
      </h4>
      <router-link
        v-if="contentOwnership.hasPriceChanges || hasRecentPriceChange"
        class="pricechangenotification link-style blue db pr2"
        :to="{ name: 'content-library-show', params: { bundleId: bundle.id } }"
      >
        <span
          v-if="contentOwnership.hasPriceChanges && hasRecentPriceChange"
        >{{ $t('Price changes & new price') }}</span>
        <span v-else-if="contentOwnership.hasPriceChanges">{{ $t('Price changes') }}</span>
        <span v-else-if="hasRecentPriceChange">{{ $t('New price') }}</span>
      </router-link>
    </td>
    <td class="tc dn dtc-m bundlecontentcell">
      <div v-for="bc in bundle.contents" :key="bc.id" @click="scrollToContent(bc.contentOwnership.id)">
        {{ bc.contentOwnership.title }}
      </div>
    </td>
    <td class="tc dn dtc-m bundlecontentcell">
      <div v-for="bc in bundle.contents" :key="bc.id" @click="scrollToContent(bc.contentOwnership.id)">
        -{{ bc.discountPercentage }}%
      </div>
    </td>
    <td class="tc w-1 dn dtc-m">
      <span>{{ bundleLicense.quantity }}</span>
    </td>
  </tr>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import LazyImg from '@/core/shared/components/utils/LazyImg';
import EULAMixin from '@/content/shared/EULAMixin';
import VisibilityObserver from '@/core/components/utils/VisibilityObserver';
import { hasPriceRecentlyChanged } from '@/core/helpers/FormatHelpers';

@Component({
  components: {
    UIButton,
    UIIcon,
    LazyImg,
    RatioContainer,
    VisibilityObserver,
  },
  props: {
    bundleLicense: { required: true },
    loading: { type: Boolean },
  },
})
export default class LibraryBundleItemTr extends mixins(EULAMixin) {
  get bundle () {
    return (this.bundleLicense && this.bundleLicense.bundle) || {};
  }

  get contentOwnership () {
    return (this.bundle && this.bundle.contentOwnership) || {};
  }

  get hasRecentPriceChange () {
    const { minutePrice, monthlyPrice, yearlyPrice } = this.contentOwnership;
    return [minutePrice, monthlyPrice, yearlyPrice].some(price => price && price.current && hasPriceRecentlyChanged(price.current));
  }

  scrollToContent (contentOwnershipId) {
    this.$emit('scrollToContent', contentOwnershipId);
  }

  get contentOwnerships () {
    return this.bundle.contents.map(x => x.contentOwnerhip);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.library-item-tr {
  --borderWidth: 6px;
  @media (--tablet) {
    --borderWidth: 10px;
  }
  & .w-1 {
    width: 1px;
  }
  & >>> .w2 {
    width: 2em;
  }
  & .currentmonthcost {
    white-space: nowrap;
  }
  & .colorindicator {
    background: var(--colorInfo);
    position: absolute;
    top: 0;
    left: calc(-1 * var(--borderWidth));
    bottom: 0;
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    width: 6px;
    @media (--tablet) {
      width: 10px;
    }
  }
  border-collapse: separate;
  border-spacing: var(--borderWidth);
  border: 0;
  & td,
  & th {
    vertical-align: middle;
    padding: 12px 2px;
    @media (--tablet) {
      padding: 8px 5px;
    }
    position: relative;
  }
  & td {
    &:before {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      @apply --panelDropShadow;
      z-index: -2;
      background-color: var(--colorWhite);
    }
    &:after {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      z-index: -1;
      background-color: var(--colorWhite);
    }
    &:first-child:after,
    &:first-child:before {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }
    &:last-child:after,
    &:last-child:before {
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 2 * var(--borderWidth));
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
  & .editcell {
    padding: 0 var(--spacingXS);
    width: 1px;
    white-space: nowrap;
    @media (--tablet) {
      padding: 0 var(--spacingMd);
    }
  }
  & .draghandle {
    cursor: grab;
    position: relative;
    width: 42px;
    text-align: center;
    fill: var(--colorFjord);
    transition: fill 0.4s ease-in-out;
    &.-disabled {
      cursor: not-allowed;
      fill: var(--colorGeyser);
    }
  }
  & .titlecell {
    max-width: 13vw;
  }
  & .bundlecontentcell div {
    text-align: left;
  }
  & .warning {
    display: block;
    text-align: left;
  }
  & .loading {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingPlaceholder;
    animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 400px 100%;
  }
  & .currentmonthusage {
    position: relative;
    &:after {
      content: "";
      z-index: 5;
      position: absolute;
      width: 100%;
      height: 140%;
      top: -20%;
      left: 0px;
      border: 1px solid var(--colorGeyser);
      border-width: 0 1px;
    }
  }
}
@keyframes loadingPlaceholder {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
